import { createTheme, responsiveFontSizes } from "@mui/material"

import { InternalRoute } from "./react-app-env";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard, faSection, faFootball, faShirt, faAddressBook } from "@fortawesome/free-solid-svg-icons";

export const DARK_THEME = responsiveFontSizes(
    createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#65AFFF',
                contrastText: "#FFF"
            },
            secondary: {
                main: '#284B63'
            }
        },
        typography: {
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
    })
);

export const LIGHT_THEME = responsiveFontSizes(
    createTheme({
        palette: {
            mode: 'light',
            primary: {
                main: '#65AFFF',
                contrastText: "#FFF"
            },
            secondary: {
                main: '#284B63'
            },
            background: {
                default: "#EEF0EB"
            },
        },
        typography: {
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
    })
);

export const ROUTES: InternalRoute[] = [
    {
        id: "/",
        name: "Home",
        icon: <FontAwesomeIcon icon={faFootball} />,
        type: "index"
    },
    {
        id: "/products",
        name: "Produkte",
        icon: <FontAwesomeIcon icon={faShirt} />,
        type: "index"
    },
    {
        id: "/contact",
        name: "Kontakt",
        icon: <FontAwesomeIcon icon={faAddressBook} />,
        type: "index"
    },

    {
        id: "/privacy-policy",
        name: "Datenschutz",
        icon: <FontAwesomeIcon icon={faSection} />,
        type: "legal"
    },
    {
        id: "/imprint",
        name: "Impressum",
        icon: <FontAwesomeIcon icon={faIdCard} />,
        type: "legal"
    },
];

export const SIDEBAR_WIDTH = 280;
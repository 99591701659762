import ReactDOM from 'react-dom/client';

import { BrowserRouter } from "react-router-dom";
import './index.css';

import App from './App';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// T Shirt configuration: 
// https://codesandbox.io/p/sandbox/t-shirt-configurator-ioxywi?file=%2Fsrc%2FCanvas.js%3A2%2C36-2%2C53

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

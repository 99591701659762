import { useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Box, ButtonBase, Divider, Drawer, Stack, AppBar, Container, Toolbar, IconButton } from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';

import logo from "../images/logo/logo.png";

import { ROUTES, SIDEBAR_WIDTH } from "../Constants";
import SideBarLink from "./SideBarLink";

const SideBar = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    return (
        <>
            <Drawer
                variant="permanent"
                sx={{
                    width: SIDEBAR_WIDTH,
                    display: {
                        xs: "none",
                        lg: "block"
                    }
                }}
                PaperProps={{
                    sx: {
                        backgroundColor: 'rgb(28, 37, 54)',
                        color: 'common.white',
                        width: SIDEBAR_WIDTH,
                    }
                }}
            >
                <ButtonBase sx={{ py: 2.5, mx: "0 auto" }} onClick={() => navigate("/")}>
                    <Box component="img" display="block" mx="auto" sx={{ height: '55px' }} src={logo} alt='Logo'></Box>
                </ButtonBase>

                <Divider />

                <Divider />

                <Content setDrawer={setOpen} />

            </Drawer>

            <AppBar sx={{
                position: "static",
                display: {
                    xs: "flex",
                    lg: "none"
                }
            }}>
                <Container maxWidth="lg">
                    <Toolbar disableGutters>
                        <IconButton
                            onClick={() => setOpen(true)}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box component="img" display="block" mx="auto" sx={{
                            position: "absolute",
                            height: '90%',
                            left: 0,
                            right: 0,
                        }} src={logo} alt='Logo'></Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Drawer
                open={open}
                onClose={() => setOpen(false)}
                variant="temporary"
                sx={{
                    width: SIDEBAR_WIDTH,
                    display: {
                        xs: "block",
                        lg: "none"
                    }
                }}
                PaperProps={{
                    sx: {
                        width: SIDEBAR_WIDTH,
                        backgroundColor: 'rgb(28, 37, 54)',
                        color: 'common.white',
                    }
                }}
            >

                <Divider />

                <Content setDrawer={setOpen} />
            </Drawer>
        </>
    );
}

export interface IContent {
    setDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}
const Content = ({ setDrawer }: IContent) => {
    return (
        <Box sx={{ py: 2.5, px: 2.5, height: "100%" }} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
            <Stack
                component="ul"
                spacing={0.5}
                sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0
                }}
                flexGrow={1}
            >
                {
                    ROUTES.filter(e => {
                        switch (e.type) {
                            case "index": return true;
                        }

                        return false;
                    }).map(e => <SideBarLink key={e.id} route={e} setDrawer={setDrawer} />)
                }
            </Stack>

            <Stack
                component="ul"
                spacing={0.5}
                sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0
                }}
            >
                {
                    ROUTES.filter(e => {
                        switch (e.type) {
                            case "legal": return true;
                        }

                        return false;
                    }).map(e => <SideBarLink key={e.id} route={e} setDrawer={setDrawer} />)
                }
            </Stack>
        </Box>
    );
}

export default SideBar;
import { Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react"

import { Stack, ThemeProvider, CssBaseline } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import { DARK_THEME, LIGHT_THEME } from "./Constants";
import SideBar from "./components/SideBar";

const IndexView = lazy(() => import("./views/IndexView"));

const ProductView = lazy(() => import("./views/ProductView"));
const ContactView = lazy(() => import("./views/ContactView"));

const ImprintView = lazy(() => import("./views/ImprintView"));
const PrivacyView = lazy(() => import("./views/PrivacyView"));

const App = () => {
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={LIGHT_THEME}>
          <CssBaseline />
          <Stack sx={{
            flexDirection: {
              xs: "column",
              lg: "row"
            }
          }}>
            <ThemeProvider theme={DARK_THEME}>
              <SideBar />
            </ThemeProvider>

            <Suspense>
              <Routes>
                <Route index path="/" element={<IndexView />} />

                <Route path="/products" element={<ProductView />} />
                <Route path="/contact" element={<ContactView />} />

                <Route path="/privacy-policy" element={<PrivacyView />} />
                <Route path="/imprint" element={<ImprintView />} />
              </Routes>
            </Suspense>
          </Stack>
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  )
}

export default App;
